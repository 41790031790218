export type ObligationStatusColor =
  | 'default'
  | 'warning'
  | 'danger'
  | 'info'
  | 'success';

export default function ObligationStatus({
  color = 'default',
  children,
  className,
  ...props
}: {
  color?: ObligationStatusColor;
  children?: React.ReactNode;
  className?: string;
}) {
  const colorClasses: Record<string, string> = {
    warning: 'text-warning-500 bg-warning-500/10',
    danger: 'text-danger-300 bg-danger-300/10',
    info: 'text-info-300 bg-info-300/10',
    success: 'text-success-300 bg-success-300/10',
  };

  return (
    <span
      className={[
        'px-2 py-0.5 rounded-full text-xs font-medium leading-[20px] tracking-[0.048px]',
        colorClasses[color],
        className,
      ].join(' ')}
      {...props}
    >
      {children}
    </span>
  );
}
