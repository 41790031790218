import {
  AutocompleteClose,
  AutocompleteItem,
  AutocompleteItemProp,
  AutocompleteProps,
  AutocompleteValue,
} from '@hol-jsp/dashboard-dsl';
import { SearchField } from './search-field';
import { useState } from 'react';

export interface SearchProps extends AutocompleteProps {
  setQuery?: (query?: AutocompleteValue) => void;
  query?: AutocompleteItemProp | null;
  placeholder?: string;
  className?: string;
}

export function Search({
  setQuery,
  query,
  placeholder,
  className,
  ...props
}: SearchProps) {
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <SearchField
      className={className}
      suffix={
        <>
          {query && (
            <AutocompleteClose
              className="text-neutral-300 hover:text-neutral-400 mr-4"
              onClick={() => {
                setSearchQuery('');
                setQuery?.(null);
              }}
            />
          )}
        </>
      }
      value={query!}
      displayValue={({ query, item }) => {
        return ['custom'].includes(
          String((item as AutocompleteItemProp)?.label)
        )
          ? query
          : String((item as AutocompleteItemProp)?.label ?? '');
      }}
      onChange={setQuery}
      onQueryChange={setSearchQuery}
      placeholder={placeholder}
      prependItem={(query: string) => {
        return query ? (
          <>
            <AutocompleteItem value={{ label: 'custom', value: query }}>
              <span className="truncate">{query}</span>
            </AutocompleteItem>
          </>
        ) : null;
      }}
      {...props}
    />
  );
}
