import { TPriorities } from 'types/notification';

export default function CompliancePriority({
  priority,
  text,
  type,
}: {
  priority: TPriorities;
  text: string;
  type?: string;
}) {
  const colorClasses: Record<
    typeof priority,
    {
      wrapperClass: string;
      number: number;
      numberClass: string;
    }
  > = {
    'very-high': {
      wrapperClass: 'text-danger-500 bg-red-600/10 border border-danger-500',
      number: 1,
      numberClass: 'bg-danger-500',
    },
    high: {
      wrapperClass:
        'text-warning-500 bg-yellow-500/10 border border-warning-500',
      number: 1,
      numberClass: 'bg-warning-500',
    },
    medium: {
      wrapperClass:
        'text-success-300 bg-emerald-700/10 border border-success-300',
      number: 1,
      numberClass: 'bg-success-300',
    },
    low: {
      wrapperClass: 'text-info-300 bg-sky-600/10 border border-info-300',
      number: 1,
      numberClass: 'bg-info-300',
    },
    'very-low': {
      wrapperClass:
        'text-neutral-300 bg-slate-400/10 border border-neutral-300',
      number: 1,
      numberClass: 'bg-neutral-300',
    },
  };

  return (
    <div
      // className={[
      //   'mr-2 mb-2 inline-flex px-2 py-1 rounded-lg text-xs font-medium leading-[20px] tracking-[0.048px] min-w-max',
      //   colorClasses[priority].wrapperClass,
      // ].join(' ')}
      className={`${type === 'change-priority-settings' ? 'mr-2 mb-2' : ''} ${colorClasses[priority].wrapperClass} inline-flex px-2 py-1 rounded-lg text-xs font-medium leading-[20px] tracking-[0.048px] min-w-max`}
    >
      <div
        className={[
          'flex items-center justify-center w-[18px] h-[18px] mr-1 rounded-full p-1 text-white text-[10px]',
          colorClasses[priority].numberClass,
        ].join(' ')}
      >
        {`P${colorClasses[priority].number}`}
      </div>
      {text}
    </div>
  );
}
