import { useMutation, useQuery } from 'react-query';
import {
  getNotifications,
  postReadAllNotification,
  postReadNotificationById,
  getNotificationById,
} from './service';
import { INofitificationParam } from 'types/notification';
import { Meta } from 'types/general';

export const useGetNotifications = (params: INofitificationParam) =>
  useQuery({
    queryKey: ['notifications', params],
    queryFn: () => getNotifications(params),
  });

export const useReadAllNotification = () =>
  useMutation({
    mutationFn: () => postReadAllNotification(),
  });

export const useReadNotificationById = () =>
  useMutation({
    mutationFn: (id: string) => postReadNotificationById(id),
  });

export const useGetNotificationById = (id: string, params: Meta) =>
  useQuery({
    queryKey: ['notifications-detail', params],
    queryFn: () => getNotificationById(id, params),
    enabled: !!id && id !== '',
  });
