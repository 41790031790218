import { Dropdown, Button } from '@hol-jsp/dashboard-dsl';
import { CaretDown } from 'phosphor-react';
import 'twin.macro';

export interface DropdownSelectorProps {
  loading: boolean;
  label: string | React.ReactNode;
  items: any[];
}

export function DropdownSelector({
  loading,
  label,
  items,
}: DropdownSelectorProps) {
  return (
    <div className="relative">
      <Dropdown
        trigger={
          <Button
            disabled={loading}
            variant="outlined"
            size="sm"
            tw="flex gap-2 justify-between items-center"
          >
            <span className="text-primary-main truncate">
              {loading ? 'Loading...' : label}
            </span>

            <CaretDown
              weight="bold"
              className="text-primary-main shrink-0"
              size={18}
            />
          </Button>
        }
        items={items}
        className="max-h-[200px] w-[200px] max-w-full overflow-y-auto"
        align="start"
        alignOffset={0}
      />
    </div>
  );
}
