import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteItemProp,
  AutocompleteLabel,
  AutocompleteProps,
} from '@hol-jsp/dashboard-dsl';
import { t } from 'i18next';
import { forwardRef } from 'react';
import { MagnifyingGlass } from 'phosphor-react';

export interface SearchFieldProps extends AutocompleteProps {}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ value, onChange, ...props }, ref) => {
    return (
      <Autocomplete
        ref={ref}
        className="flex-grow"
        placeholder={t('userManagement.search')}
        value={value}
        onChange={onChange}
        prefix={<MagnifyingGlass size={20} />}
        customValueRenderer={(value: string) => {
          return value;
        }}
        clearQueryOnClose={false}
        displayValue={({ query, item }) => {
          return ['name', 'email', 'all'].includes(
            String((item as AutocompleteItemProp)?.value)
          )
            ? query
            : String((item as AutocompleteItemProp)?.label ?? '');
        }}
        prependItem={(query: string) => {
          return query ? (
            <>
              <AutocompleteItem value={{ label: 'All', value: 'all', query }}>
                <span className="truncate">{query}</span>
              </AutocompleteItem>
              <AutocompleteItem
                value={{ label: 'Full Name', value: 'name', query }}
              >
                {({ active }) => (
                  <>
                    <span className="truncate">{query}</span>
                    <AutocompleteLabel active={active}>
                      in Full Name
                    </AutocompleteLabel>
                  </>
                )}
              </AutocompleteItem>
              <AutocompleteItem
                value={{ label: 'Email', value: 'email', query }}
              >
                {({ active }) => (
                  <>
                    <span className="truncate">{query}</span>
                    <AutocompleteLabel active={active}>
                      in Email
                    </AutocompleteLabel>
                  </>
                )}
              </AutocompleteItem>
            </>
          ) : null;
        }}
        inputClass={({ query, item }) => {
          return query &&
            ['name', 'email'].includes(
              String((item as AutocompleteItemProp)?.value)
            )
            ? '!flex-grow-0'
            : '';
        }}
        inputStyle={({ query }) => {
          return {
            width: query.length > 90 ? '100%' : query.length * 8 + 20,
            paddingRight: 4,
            maxWidth: '100%',
          };
        }}
        selectionRenderer={({ query, item }) => {
          return item
            ? query &&
                ['name', 'email'].includes(
                  String((item as AutocompleteItemProp)?.value)
                ) && (
                  <div className="flex-1">
                    <AutocompleteLabel>
                      in {(item as AutocompleteItemProp)?.label}
                    </AutocompleteLabel>
                  </div>
                )
            : null;
        }}
        hideEmptyResult
        optionsClass="empty:hidden"
        clearable
        {...props}
      />
    );
  }
);

SearchField.displayName = 'SearchField';
