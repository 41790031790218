import { cx } from '@hol-jsp/dashboard-dsl';
import { CircleNotch } from 'phosphor-react';

export function Spinner({
  className = '',
  size = 16,
  ...props
}: {
  className?: string;
  size?: number;
}) {
  return (
    <CircleNotch
      className={cx('animate-spin', className)}
      size={size}
      {...props}
    />
  );
}
