import { TStatuses } from 'types/notification';

export const statusText = (status: TStatuses) => {
  switch (status) {
    case 'unassigned':
      return 'Unassigned';
    case 'assigned':
      return 'In Progress';
    case 'ready-to-review':
      return 'In Progress';
    case 'comply':
      return 'Comply';
    case 'amended':
      return 'Amended';
    case '':
      return 'Unassigned';
    default:
      return status;
  }
};

export default function ComplianceStatus({ status }: { status: TStatuses }) {
  const colorClasses: Record<typeof status, string> = {
    // Inactive: 'text-neutral-200 bg-neutral-200/10',
    '': 'text-neutral-400 bg-neutral-400/10',
    unassigned: 'text-neutral-400 bg-neutral-400/10',
    assigned: 'text-warning-300 bg-warning-300/10',
    'ready-to-review': 'text-warning-300 bg-warning-300/10',
    comply: 'text-success-300 bg-success-300/10',
    amended: 'text-warning-500 bg-warning-500/10',
    repealed: 'text-danger-300 bg-danger-300/10',
    'in-effect': 'none',
    // Renew: 'text-info-300 bg-info-300/10', // diluar status jadinya, sementara di comment
  };

  return (
    <>
      {status !== 'in-effect' && (
        <span
          className={[
            'px-2 py-1 rounded-lg text-xs font-medium leading-[20px] tracking-[0.048px] min-w-max',
            colorClasses[status],
          ].join(' ')}
        >
          {statusText(status)}
        </span>
      )}
    </>
  );
}
