import { ObligationHistory } from './types';
import { TextDS2 } from '@hol-jsp/dashboard-dsl';
import classNames from 'classnames';
import dayjs from 'dayjs';
import ObligationBadges from 'page-components/regulation-detail/obligation-badges';

export function ObligationHistoryList({
  items,
  selectedItem,
  setSelectedItem,
  onItemClick,
}: {
  items: ObligationHistory[];
  onItemClick?: (item: ObligationHistory) => void;
  selectedItem?: ObligationHistory;
  setSelectedItem?: (item: ObligationHistory) => void;
}) {
  return (
    <div className="space-y-4">
      {items.map((item) => (
        <ObligationHistoryItem
          item={item}
          key={item.id}
          selected={selectedItem?.id === item.id}
          onClick={() => {
            setSelectedItem?.(item);
            onItemClick?.(item);
          }}
        />
      ))}
    </div>
  );
}

export function ObligationHistoryItem({
  item,
  selected,
  onClick,
}: {
  item: ObligationHistory;
  selected?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={classNames(
        'p-[10px] flex flex-col gap-2',
        selected ? 'bg-info-100' : 'bg-neutral-50',
        onClick && 'hover:cursor-pointer'
      )}
      onClick={onClick}
    >
      <header className={classNames('flex items-center justify-between')}>
        <div className="flex gap-2 items-center">
          <ObligationBadges
            status={item.obligation_status}
            isNew={item.is_new}
            isActivated={item.is_activated}
            version={item.obligation_version}
          />
        </div>
        <TextDS2
          agDesktop="Desktop/Body 3/Regular"
          agMobile="Desktop/Body 3/Regular"
          color="Neutral/300"
        >
          {dayjs(item.created_at).format('DD MMM YYYY')}
        </TextDS2>
      </header>
      <TextDS2
        agMobile="Desktop/Body 3/Regular"
        agDesktop="Desktop/Body 3/Regular"
      >
        {item.title}
      </TextDS2>
    </div>
  );
}
