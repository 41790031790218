import ObligationStatus, { ObligationStatusColor } from './obligation-status';

export default function ObligationBadges({
  status,
  isNew,
  isActivated,
  version,
  hideVersion,
}: {
  status?: string;
  isNew?: boolean;
  isActivated?: boolean;
  version?: number; // obligation_version
  hideVersion?: boolean;
}) {
  const statusColors: Record<string, ObligationStatusColor> = {
    Amended: 'warning',
    Repealed: 'danger',
  };
  return (
    <>
      {status && status !== 'In Effect' && (
        <ObligationStatus
          color={statusColors[status]}
          data-testid="obligationStatus"
        >
          {status}
        </ObligationStatus>
      )}
      {isNew && (
        <ObligationStatus color="success" data-testid="obligationLabel">
          New
        </ObligationStatus>
      )}
      {isActivated && (
        <ObligationStatus color="info" data-testid="obligationStatus">
          Active
        </ObligationStatus>
      )}
      {version && !hideVersion && (
        <ObligationStatus color="warning" data-testid="obligationVersion">
          Version {version}
        </ObligationStatus>
      )}
    </>
  );
}
