import {
  ObligationChecklistResponse,
  ObligationOptionsResponse,
  ObligationRegulationResponse,
  RegulationAnalysisResponse,
  RegulationHistoryResponse,
  RegulationObligationDetailResponse,
  SectorResponse,
  ObligationHistoryResponse,
  RegulationDetailResponse,
  RegulationDetailServiceParams,
} from 'types/regulation-detail';
/* eslint-disable import/prefer-default-export */
import { getCompanyId } from 'helper/utils';
import axios from 'modules/axios';

export interface SectorTopic {
  id: string;
  topic_id: string;
  code: string;
  title: string;
  is_subscribed: number;
  is_master: number;
  order: number;
  sector_id: string;
  company_id: string;
  created_at: string;
  updated_at: string;
  language_id: string;
  level: number;
  count_obligation: number;
  count_activable: number;
  count_inactivable: number;
  count_confirmable: number;
  topics: SectorTopic[];
  topic_parent_id: string;
  topic_level: string;
}

export interface SectorTopicResponse {
  data: SectorTopic[];
  message: string;
}

export async function getDetailRegulation({
  regulationId,
  params,
}: RegulationDetailServiceParams & {
  params: Record<string, any>;
}) {
  const companyId = getCompanyId();
  return axios.get<RegulationDetailResponse>(`v1/regulations/${regulationId}`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}

export interface GetRegulationOptionsParams {
  page: string | number;
  limit: string | number;
  search: string;
  language_code: string;
  language_id: string;
  is_affected_change: boolean;
}

export function getRegulationOptions(
  hierarchyId?: string,
  regulationId?: string,
  params?: Partial<GetRegulationOptionsParams>
) {
  const companyId = getCompanyId();
  return axios.get<ObligationOptionsResponse>(`v1/obligations/options`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}

export async function getRegulationAnalysis({
  regulationId,
  languageId,
}: RegulationDetailServiceParams) {
  const companyId = getCompanyId();
  return axios.get<RegulationAnalysisResponse>(
    `v1/regulations/${regulationId}/analysis`,
    {
      params: {
        company_id: companyId,
        language_id: languageId,
      },
    }
  );
}

export async function getRegulationHistory({
  regulationId,
  languageId,
}: RegulationDetailServiceParams) {
  const companyId = getCompanyId();
  return axios.get<RegulationHistoryResponse>(
    `v1/regulations/${regulationId}/history`,
    {
      params: {
        company_id: companyId,
        language_id: languageId,
      },
    }
  );
}

export interface GetSectorListParams {
  language_code: string;
  language_id?: string;
  page: number;
  limit: number;
  search: string;
  order_by: string;
  order_type: string;
}

export function getSectorList(params: Partial<GetSectorListParams>) {
  const companyId = getCompanyId();

  return axios.get<SectorResponse>(`v1/sectors`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}

export interface GetTopicListParams {
  language_code?: string;
  language_id?: string;
  page: number;
  limit: number;
  search: string;
  order_by: string;
  order_type: string;
}

export function getTopicList(
  sectorId: string,
  params: Partial<GetTopicListParams>
) {
  const companyId = getCompanyId();

  return axios.get<SectorTopicResponse>(`/v1/topics`, {
    params: {
      ...params,
      sector_id: sectorId,
      company_id: companyId,
    },
  });
}

export interface SearchParams {
  name: string;
  value: string;
}

export interface GetRegulationObligationsParams {
  language_code: string;
  page: number;
  limit: number;
  search?: string | SearchParams[];
  order_by: string;
  order_type: string;
  filter?: string;
  title?: string;
  code?: string;
  version_history?: string;
  compliance_status?: string;
  obligation_type?: string;
  obligation_penalty?: string;
  applicability_status?: string;
  topic?: string;
  regulation?: string;
  sector?: string;
}

export interface GetRegulationObligationDetailParams {
  language_code?: string;
  language_id?: string;
}

export function getRegulationObligationDetail(
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) {
  const companyId = getCompanyId();

  return axios.get<RegulationObligationDetailResponse>(
    `v1/obligations/${obligationId}`,
    {
      params: {
        ...params,
        company_id: companyId,
      },
    }
  );
}

export function getRegulationObligationChecklist(
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) {
  const companyId = getCompanyId();

  return axios.get<ObligationChecklistResponse>(
    `v1/obligations/${obligationId}/checks`,
    {
      params: {
        ...params,
        company_id: companyId,
      },
    }
  );
}

export function getRegulationObligationRegulations(
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) {
  const companyId = getCompanyId();

  return axios.get<ObligationRegulationResponse>(
    `v1/obligations/${obligationId}/regulations`,
    {
      params: {
        ...params,
        company_id: companyId,
      },
    }
  );
}

export function getRegulationObligationHistory(
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) {
  const companyId = getCompanyId();

  return axios.get<ObligationHistoryResponse>(
    `v1/obligations/${obligationId}/history`,
    {
      params: {
        ...params,
        company_id: companyId,
      },
    }
  );
}
