import { PaginationData, TextDS2, Pagination } from '@hol-jsp/dashboard-dsl';
import { t } from 'i18next';
import 'twin.macro';

export function TablePagination({
  pagination,
  setPagination,
}: {
  pagination: PaginationData;
  setPagination: React.Dispatch<React.SetStateAction<PaginationData>>;
}) {
  return (
    <div tw="mt-4 flex justify-between">
      <div tw="flex items-center">
        <TextDS2
          agDesktop="Desktop/Caption/Medium"
          agMobile="Desktop/Caption/Medium"
          tw="font-inter font-medium text-neutral-300 mr-3"
        >
          {t('rowsPerPage')}
        </TextDS2>
        <select
          tw="px-2 py-[5px] rounded-[4px] bg-white border-[1px] border-neutral-75"
          onChange={(e) => {
            setPagination({
              ...pagination,
              limit: Number(e.target.value),
              page: 1,
            });
          }}
          value={pagination.limit}
        >
          <option value="10" tw="text-body-3 text-primary-main">
            10
          </option>
          <option value="20" tw="text-body-3 text-primary-main">
            20
          </option>
          <option value="50" tw="text-body-3 text-primary-main">
            50
          </option>
          <option value="100" tw="text-body-3 text-primary-main">
            100
          </option>
        </select>
      </div>
      <Pagination
        page={pagination.page}
        lastPage={pagination.page_count}
        onChangePage={(page) => {
          setPagination({
            ...pagination,
            page,
          });
        }}
        updateWhenPropsChange
      />
    </div>
  );
}
