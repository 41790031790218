import { Checkbox } from '@hol-jsp/dashboard-dsl';
import { ObligationChecklist } from './types';

export default function ObligationChecklistItem({
  item,
  index,
}: {
  item: ObligationChecklist;
  index: number;
}) {
  return (
    <li
      className="text-xs text-neutral-400 font-medium flex items-start gap-1"
      key={item.id}
    >
      <Checkbox checked={false} disabled />
      {item.title}
    </li>
  );
}
