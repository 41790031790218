import { useQuery } from 'react-query';
import {
  GetRegulationObligationDetailParams,
  GetRegulationOptionsParams,
  GetSectorListParams,
  GetTopicListParams,
  getDetailRegulation,
  getRegulationAnalysis,
  getRegulationHistory,
  getRegulationObligationChecklist,
  getRegulationObligationDetail,
  getRegulationObligationRegulations,
  getRegulationOptions,
  getSectorList,
  getTopicList,
  getRegulationObligationHistory,
} from './service';
import { RegulationDetailServiceParams } from 'types/regulation-detail';

export const useGetDetailRegulation = (params: {
  regulationId: string;
  params: Record<string, any>;
}) =>
  useQuery({
    queryKey: ['regulation', params],
    queryFn: () => getDetailRegulation(params),
  });

export const useGetDetailRegulationFromHistory = (params: {
  regulationId: string;
  params: Record<string, any>;
}) =>
  useQuery({
    queryKey: ['regulation-from-history', params],
    queryFn: () => getDetailRegulation(params),
    enabled: params.regulationId !== '',
  });

export const useGetRegulationAnalysis = (
  params: RegulationDetailServiceParams
) =>
  useQuery({
    queryKey: ['regulation-analysis', params],
    queryFn: () => getRegulationAnalysis(params),
  });

export const useGetRegulationHistory = (
  params: RegulationDetailServiceParams
) =>
  useQuery({
    queryKey: ['regulation-history', params],
    queryFn: () => getRegulationHistory(params),
  });

export const useGetRegulationOptions = (
  hierarchyId: string,
  regulationId: string,
  params: Partial<GetRegulationOptionsParams>
) =>
  useQuery({
    queryKey: ['regulation-options', hierarchyId, regulationId, params],
    queryFn: () => getRegulationOptions(hierarchyId, regulationId, params),
  });

export const useGetSectorList = (params: Partial<GetSectorListParams>) =>
  useQuery({
    queryKey: ['sector-list', params],
    queryFn: () => getSectorList(params),
  });

export const useTopicList = (
  sectorId: string,
  params: Partial<GetTopicListParams>
) =>
  useQuery({
    queryKey: ['topic-list', params],
    queryFn: () => getTopicList(sectorId, params),
  });

export const useGetRegulationObligationDetail = (
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) =>
  useQuery({
    queryKey: ['regulation-obligations-detail', obligationId, params],
    queryFn: () => getRegulationObligationDetail(obligationId, params),
  });

export const useGetRegulationObligationChecklist = (
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) =>
  useQuery({
    queryKey: ['regulation-obligations-checklist', obligationId, params],
    queryFn: () => getRegulationObligationChecklist(obligationId, params),
  });

export const useGetRegulationObligationRegulations = (
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) =>
  useQuery({
    queryKey: ['regulation-obligations-regulations', obligationId, params],
    queryFn: () => getRegulationObligationRegulations(obligationId, params),
  });

export const useGetRegulationObligationHistory = (
  obligationId: string,
  params?: Partial<GetRegulationObligationDetailParams>
) =>
  useQuery({
    queryKey: ['regulation-obligations-history', obligationId, params],
    queryFn: () => getRegulationObligationHistory(obligationId, params),
  });
