import { cx } from '@hol-jsp/dashboard-dsl';

export default function FilterCard({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cx(
        'w-[407px] max-h-[90vh] overflow-auto p-4 pb-0 bg-white rounded-md shadow border border-slate-400 flex-col inline-flex fixed bottom-0 right-0 space-y-2 z-[10]',
        className
      )}
    >
      {children}
    </div>
  );
}
