import ObligationChecklistItem from './ObligationChecklistItem';
import ObligationChecklistList from './ObligationChecklistList';
import { ObligationChecklist } from './types';
import { Card } from '../Card';
import { Divider } from '@hol-jsp/dashboard-dsl';
import { t } from 'i18next';

export default function ObligationChecklistCard({
  items,
}: {
  items: ObligationChecklist[];
}) {
  return (
    <div>
      <h3 className="text-sm font-medium text-neutral-300">
        {t('common.total')}
        <span className="text-neutral-500 font-medium mx-1.5">
          {items?.length}
        </span>
        {t('common.checklist')}
      </h3>

      <Divider className="my-2" />

      <ObligationChecklistList>
        {items?.map((item, index) => (
          <ObligationChecklistItem item={item} index={index} key={item.id} />
        ))}
      </ObligationChecklistList>
    </div>
  );
}
