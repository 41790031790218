import { Checks } from 'phosphor-react';

import { Button, TextDS2 } from '@hol-jsp/dashboard-dsl';
import { useTranslation } from 'react-i18next';
import { ThemeButton } from 'components';

const MarkAllAsReadBtn = ({
  onClick,
  size,
}: {
  onClick: VoidFunction;
  size?: 'sm' | 'md';
}) => {
  const { t } = useTranslation();

  const textSize = size === 'sm' ? 'Small' : 'Medium';

  return (
    <ThemeButton variant="text" className="!px-0 !h-auto" onClick={onClick}>
      <div className="flex flex-row gap-2 items-center">
        <Checks weight="bold" size={20} />
        <TextDS2
          agDesktop={`Button/${textSize}`}
          agMobile={`Button/${textSize}`}
        >
          {t('notification.markAllAsRead')}
        </TextDS2>
      </div>
    </ThemeButton>
  );
};

export default MarkAllAsReadBtn;
