import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { TextDS2 } from '@hol-jsp/dashboard-dsl';

interface IProps {
  label?: string;
  // eslint-disable-next-line no-unused-vars
  onToggleChange?: (value: boolean) => void;
  labelClassName?: string;
  value?: boolean;
}

const Toggle = ({ value, label, onToggleChange, labelClassName }: IProps) => {
  const [enabled, setEnabled] = useState(value || false);

  const handleChange = (newValue: boolean) => {
    setEnabled(newValue);
    if (onToggleChange) {
      onToggleChange(newValue);
    }
  };

  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={enabled}
          onChange={handleChange}
          className={`${
            enabled ? 'bg-primary-300' : 'bg-neutral-100'
          } relative inline-flex h-6 w-10 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              enabled ? 'translate-x-5' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
        <Switch.Label className="ml-2">
          <TextDS2
            color="Neutral/400"
            agMobile="Desktop/Body 3/Medium"
            agDesktop="Desktop/Body 3/Medium"
            className={labelClassName}
          >
            {label}
          </TextDS2>
        </Switch.Label>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
