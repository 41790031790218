import { useQuery } from 'react-query';
import { getListHierarchy, getListRegulationHierarchy } from './service';
import { PaginationData } from '@hol-jsp/dashboard-dsl';

export const useGetListHierarchy = (
  params: PaginationData & { search?: string }
) =>
  useQuery({
    queryKey: ['hierarchy', params],
    queryFn: () => getListHierarchy(params),
    keepPreviousData: true,
  });

export const useGetListRegulationHierarchy = (
  params: PaginationData & { search?: string }
) =>
  useQuery({
    queryKey: ['hierarchy', params],
    queryFn: () => getListRegulationHierarchy(params),
    keepPreviousData: true,
  });
