import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Text, cx } from '@hol-jsp/dashboard-dsl';
import { IconProps } from 'phosphor-react';
import React from 'react';
import { SideMenuToggle } from './SideMenuToggle';
import ExpandedMenus from './ExpandedMenus';
import CollapsedMenus from './CollapsedMenus';
import { getOrgSettingsFromCookies } from 'helper';

export interface SideNavNavigationProps {
  label: string;
  href: string;
  active: boolean;
  icon?: React.ReactNode;
  childNav?: SideNavNavigationProps[];
  testid?: string;
  permissions?: string[];
}

export interface SideNavContentProps {
  title: string;
  nav?: SideNavNavigationProps[];
  fullyShown: boolean;
  setFullyShown: (value: boolean) => void;
  testid?: string;
  isUpdating?: boolean;
}

function SideNavContainer({
  hidden,
  fullyShown,
  children,
  isUpdating,
}: {
  hidden: boolean;
  fullyShown: boolean;
  children: React.ReactNode;
  isUpdating?: boolean;
}) {
  const orgSettings = getOrgSettingsFromCookies();
  return (
    <div
      className={cx(
        'fixed h-full border-r-[2px] border-r-neutral-50 transition-all ease-in-out duration-500 z-10 bg-white',
        hidden ? 'invisible' : '',
        !fullyShown ? 'w-[70px]' : 'w-[234px]',
        isUpdating ? 'pt-[84px]' : 'pt-[68px]'
      )}
      style={{
        backgroundColor: orgSettings?.sidenav_background_color,
      }}
    >
      {children}
    </div>
  );
}

export default function SideNav({
  title,
  nav,
  fullyShown,
  setFullyShown,
  testid,
  isUpdating,
}: SideNavContentProps) {
  const orgSettings = getOrgSettingsFromCookies();
  return (
    <SideNavContainer
      data-testid={testid}
      hidden={false}
      fullyShown={fullyShown}
      isUpdating={isUpdating}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .forceStyle:hover {
            background-color: ${orgSettings?.sidenav_active_menu_background_color} !important;
            color: ${orgSettings?.sidenav_active_text_and_icon_color} !important;
          }
        `,
        }}
      />
      <div
        className={cx([
          'flex items-center pt-6 w-full shrink-0',
          fullyShown ? 'justify-end px-6' : 'justify-center px-6',
        ])}
      >
        <SideMenuToggle
          mini={!fullyShown}
          onClick={() => setFullyShown(!fullyShown)}
          data-testid="min-max-sidebar"
        />
      </div>

      <div
        className={cx([
          'py-[26px] font-inter overflow-hidden flex flex-col justify-center',
          fullyShown ? 'px-[24px]' : 'px-1',
        ])}
      >
        {fullyShown && (
          <Text
            as="body-2"
            className="font-semibold text-neutral-500 mb-4 truncate"
            style={{
              color: orgSettings?.sidenav_background_color_text_title,
            }}
          >
            {title}
          </Text>
        )}

        {fullyShown && nav ? (
          <ExpandedMenus nav={nav} />
        ) : (
          <CollapsedMenus nav={nav!} />
        )}
      </div>
    </SideNavContainer>
  );
}
