import { gql } from 'apollo-boost';

export const LISTEN_EXECUTE_CONTENT = gql`
  subscription ListenExecuteContent($token: String!, $company_id: String!) {
    content {
      listen_execute_content(company_id: $company_id, token: $token) {
        is_loading
        total_progress
        current_progress
        error
        status
        message
      }
    }
  }
`;
