import { BubbleBox, Button, Text } from '@hol-jsp/dashboard-dsl';
import { useTranslation } from 'react-i18next';
import 'twin.macro';
import Divider from './Divider';
import { Buildings, Bookmarks, ArchiveBox } from 'phosphor-react';
import { useState } from 'react';

const HelpBubble = ({
  onClick,
  showFeedbackModal,
  setShowFeedbackModal,
}: {
  onClick: () => void;
  showFeedbackModal: boolean;
  setShowFeedbackModal: (data: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <BubbleBox showHandler={onClick}>
      <div className="absolute top-full right-[89px] mt-5 mr-12 -translate-y-3 z-10">
        <div className="bg-white w-[277px] p-3 text-black rounded border-[1px] border-neutral-200">
          <div className="flex flex-col">
            <Text as="body-1" className="font-inter font-bold px-3">
              {t('helpBubble.title')}
            </Text>
            <Divider />
            <div className="-mx-4">
              <Button
                variant="text"
                className="!text-primary-main !w-full !flex !items-center"
                onClick={() => {
                  setShowFeedbackModal(true);
                }}
              >
                <ArchiveBox size={20} className="mr-2" />
                {t('helpBubble.sendFeedback')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BubbleBox>
  );
};

export default HelpBubble;
