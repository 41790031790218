/* eslint-disable import/prefer-default-export */
import { getCookies } from '@hol-jsp/dashboard-dsl';
import axios from 'modules/axios';
import { User } from 'types/user';
import { RoleCompliance as Role } from 'types/role-compliance-detail';
import { Company } from 'types/general';

export interface LoginReqBody {
  email: string;
  password: string;
  keep_sign_in?: boolean;
  slug: string;
  type?: string;
}

export interface LoginAzureReqBody {
  access_token?: string;
  email: string;
  slug: string;
  password: string;
}

export interface ResetPasswordReqBody {
  new_password: string;
  confirm_new_password: string;
}

export interface ForgotPasswordBody {
  email: string;
}

export interface CheckUsernameBody {
  username: string;
}

export interface CheckTokenBody {
  token: string;
}

export interface CheckUsernameResp {
  data: boolean;
  message: string;
}

export interface Token {
  email: string;
  name: string;
  receive_whatsapp_notif: boolean;
  whatsapp_number: string;
}

export interface CheckTokenResp {
  data: Token;
  message: string;
}

export interface AcceptInvitationBody {
  name: string;
  username?: string;
  email: string;
  password: string;
  token?: string;
}

export interface LoginResp {
  data: {
    token: string;
    user: User;
    roles: Role;
    companies: Company[];
  };
  message: string;
}

export async function login(data: LoginReqBody) {
  const res = await axios.post<LoginResp>(`v1/auth/login`, data, {
    withCredentials: true,
  });
  return res.data;
}

export async function loginAzure(data: LoginAzureReqBody) {
  const res = await axios.post<LoginResp>(`v1/auth/login-azure`, data, {
    withCredentials: true,
  });
  return res.data;
}

export async function logout() {
  const token = getCookies('token');

  const res = await axios.get(`v1/auth/logout`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}

export async function validateResetPasswordToken(token: string) {
  return axios
    .post(`v1/auth/reset-password/validate`, {
      token,
    })
    .then((res) => res.data);
}

export async function resetPassword({
  data,
  token,
}: {
  data: ResetPasswordReqBody;
  token: string;
}) {
  return axios
    .post(`v1/auth/reset-password`, {
      password: data.new_password,
      password_confirmation: data.confirm_new_password,
      token: token,
    })
    .then((res) => res.data);
}

export async function postForgotPassword(data: ForgotPasswordBody) {
  const res = await axios.post(`/v1/auth/forgot-password`, data);
  return res.data;
}

export async function postCheckUsername(data: CheckUsernameBody) {
  const res = await axios.post<CheckUsernameResp>(
    `/v1/auth/invitation/validate-username`,
    data
  );
  return res.data;
}

export async function postCheckToken(data: CheckTokenBody) {
  const res = await axios.post<CheckTokenResp>(
    `/v1/auth/invitation/validate`,
    data
  );
  return res.data;
}

export async function postAcceptInvitation(data: AcceptInvitationBody) {
  const res = await axios.post(`/v1/auth/invitation/accept`, data);
  return res.data;
}
