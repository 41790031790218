import { t } from 'i18next';
import Image from 'next/image';
import { TextDS2 } from '@hol-jsp/dashboard-dsl';

export function EmptyState({
  hideImage,
  message,
  description,
}: {
  hideImage?: boolean;
  message?: string;
  description?: string;
}) {
  return (
    <div className="flex flex-col py-6">
      {!hideImage && (
        <div className="flex justify-center items-center">
          <Image
            src="/images/lia-confused.svg"
            alt="Empty"
            width={260}
            height={164}
          />
        </div>
      )}

      <TextDS2
        agMobile="Desktop/Caption/Semi Bold"
        agDesktop="Desktop/Caption/Semi Bold"
        className="text-sm text-center px-6 text-neutral-300 mt-3"
      >
        {message ?? t('common.empty')}
      </TextDS2>

      {!!description && (
        <TextDS2
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          color="Neutral/300"
          className="text-center mt-1"
        >
          {description}
        </TextDS2>
      )}
    </div>
  );
}
