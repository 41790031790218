import classNames from 'classnames';

export interface CardProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

export function Card({ children, className, id, ...props }: CardProps) {
  return (
    <div
      className={classNames(
        'p-6 bg-white border-neutral-75 border-[1px] rounded-[4px] font-inter',
        className
      )}
      id={id}
      {...props}
    >
      {children}
    </div>
  );
}
