import { cx } from '@hol-jsp/dashboard-dsl';

export default function FilterCardFooter({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cx(
        'self-stretch justify-end items-center gap-4 inline-flex sticky bottom-0 bg-white pb-4',
        className
      )}
    >
      {children}
    </div>
  );
}
