import { t } from 'i18next';
import Image from 'next/image';
import { ThemeButton } from '../ThemeButton';
import { TextDS2, cx } from '@hol-jsp/dashboard-dsl';
import { useRouter } from 'next/router';
import { getUserEntryPoint } from '@/helper/index';
import { useTrackedAction } from '@/helper/actionTracker';

export function Forbidden({ fullPage = false }: { fullPage?: boolean }) {
  const router = useRouter();
  const trackAction = useTrackedAction();

  if (router.asPath !== '/page-403') {
    router.replace('/page-403');
  }

  const handleBackTo = () => {
    trackAction({
      action: 'click-back-to-homepage',
      type: 'back-to-homepage',
      category: 'Page 403 - Back To Homepage',
      status: 'success',
    });

    router.push(getUserEntryPoint(false));
  };

  return (
    <div className="bg-neutral-50 px-[40px] py-[56px]">
      <div
        className={cx(
          'w-full mx-auto flex flex-col items-center justify-center text-center bg-white',
          fullPage && 'h-[calc(100vh-112px)]'
        )}
      >
        <Image
          src="/images/forbidden.png"
          alt="Forbidden"
          width={326}
          height={250}
          className="block mb-8"
        />
        <TextDS2
          agDesktop="Desktop/Title 1/Extra Bold"
          agMobile="Desktop/Title 1/Extra Bold"
          color="Neutral/400"
          className="mb-2"
        >
          {t('forbidden.title')}
        </TextDS2>
        <TextDS2
          agDesktop="Desktop/Body 2/Medium"
          agMobile="Desktop/Body 2/Medium"
          color="Neutral/300"
          className="mb-6 max-w-[545px]"
        >
          {t('forbidden.subtitle')}
        </TextDS2>
        <ThemeButton onClick={handleBackTo}>
          {t('forbidden.button')}
        </ThemeButton>
      </div>
    </div>
  );
}
