import { TextDS2 } from '@hol-jsp/dashboard-dsl';
import React from 'react';

const PopupMessageDetail = ({
  upperText,
  bottomText,
  customDetail,
}: {
  upperText?: string;
  bottomText?: string;
  customDetail?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col space-y-2 px-2 py-1 bg-neutral-50 border-[1px] border-neutral-75">
      {upperText && (
        <TextDS2
          agDesktop="Desktop/Body 3/Semi Bold"
          agMobile="Desktop/Body 3/Semi Bold"
          color="Neutral/300"
        >
          {upperText}
        </TextDS2>
      )}
      {bottomText && (
        <TextDS2
          agDesktop="Desktop/Body 3/Regular"
          agMobile="Desktop/Body 3/Regular"
          color="Neutral/300"
          className="italic"
        >
          {bottomText}
        </TextDS2>
      )}
      {customDetail && customDetail}
    </div>
  );
};

export default PopupMessageDetail;
