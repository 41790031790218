import { forwardRef } from 'react';
import classNames from 'classnames';
import { CaretLeft } from 'phosphor-react';

export interface SideMenuToggleProps {
  children?: React.ReactNode;
  as?: React.ElementType;
  mini?: boolean;
  onClick?: () => void;
}

export const SideMenuToggle = forwardRef<
  HTMLButtonElement,
  SideMenuToggleProps
>(({ children, as: Component = 'button', mini, ...rest }, ref) => {
  return (
    <Component
      className={classNames(
        'rounded-full w-6 h-6 bg-neutral-100 text-white shrink-0 flex items-center justify-center shadow-small'
      )}
      {...rest}
      ref={ref}
    >
      {children ?? (
        <CaretLeft
          size={12}
          className={classNames(
            'transform transition-transform duration-500',
            mini && 'rotate-180'
          )}
        />
      )}
    </Component>
  );
});

SideMenuToggle.displayName = 'SideMenuToggle';
