import { TextDS2 } from '@hol-jsp/dashboard-dsl';

export interface SearchResultTextProps {
  text: string | null;
  searchQuery?: string;
  show?: boolean;
  className?: string;
  onClick?: () => void;
  postfix?: React.ReactNode;
}

export const SearchResultText = ({
  text,
  searchQuery,
  show = true,
  className,
  postfix,
  ...props
}: SearchResultTextProps) => {
  return (
    <TextDS2
      color="Neutral/300"
      agDesktop="Desktop/Body 3/Medium"
      agMobile="Desktop/Body 3/Medium"
      className={className}
      {...props}
    >
      {searchQuery && show ? (
        <span
          dangerouslySetInnerHTML={{
            __html:
              (text as string).replace(
                new RegExp(searchQuery, 'gi'),
                (match) =>
                  `<span class="highlighted bg-[#F8CA0F]">${match}</span>`
              ) ?? text,
          }}
        />
      ) : (
        text
      )}
      {postfix}
    </TextDS2>
  );
};
