import { Button, ButtonProps, cx } from '@hol-jsp/dashboard-dsl';

// temp fix emotion css different type
export interface ThemeButtonProps extends ButtonProps {
  css?: any;
}

export function ThemeButton({
  className,
  size = 'md',
  variant = 'solid',
  block,
  shadow,
  isClickable,
  ...props
}: ThemeButtonProps) {
  return (
    <Button
      size={size}
      variant={variant}
      className={cx(className, 'theme-button', variant)}
      block={block}
      shadow={shadow}
      isClickable={isClickable}
      {...props}
    />
  );
}
