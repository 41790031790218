import { getCompanyId } from '@/helper/utils';
import { HierarchyResponse } from '@/types/hierarchy';
import { PaginationData } from '@hol-jsp/dashboard-dsl';
import axios from 'modules/axios';

export async function getListHierarchy(
  params: PaginationData & { search?: string }
) {
  const companyId = getCompanyId();
  return axios.get<HierarchyResponse>(`v1/hierarchies`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}

export async function getListRegulationHierarchy(
  params: PaginationData & { search?: string }
) {
  const companyId = getCompanyId();
  return axios.get<any>(`v1/hierarchies/for-option`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}
