import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

export function Dropdown({
  trigger,
  children,
  value,
  onChange,
  optionsClass,
}: {
  children?: React.ReactNode;
  trigger?: React.ReactNode;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  optionsClass?: string;
}) {
  return (
    <Listbox
      as="div"
      className="relative inline-block text-left"
      value={value}
      onChange={onChange}
    >
      {trigger}

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options
          className={classNames(
            'absolute mt-2 py-1 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
            optionsClass
          )}
        >
          {children}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}

export function DropdownOption({
  value,
  children,
  label,
}: {
  value: string | number;
  label?: string | number;
  children?: React.ReactNode;
}) {
  return (
    <Listbox.Option
      value={value}
      as="button"
      className={({ active }) =>
        classNames(
          'px-4 py-2 w-full text-left hover:bg-primary-400 hover:text-white',
          active ? 'bg-primary-400 text-white' : 'text-neutral-700'
        )
      }
    >
      {label ?? children}
    </Listbox.Option>
  );
}
