import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  Monitor,
  Gear,
  Files,
  Stack,
  Warning,
  Users,
  UserList,
  ArrowSquareOut,
} from 'phosphor-react';
import Image from 'next/image';

import { Button, Checkbox, Divider, TextDS2 } from '@hol-jsp/dashboard-dsl';
import { TextDS2Ag } from '@hol-jsp/dashboard-dsl/dist/types/components/elements/text-ds2/text-ds2.types';

import NotificationPriority from './NotificationPriority';
import NotificationStatus from './NotificationStatus';

import { INotification, TPriorities } from 'types/notification';

dayjs.extend(utc);
dayjs.extend(timezone);

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type TypeClasses = {
  [key: string]: string;
};

import 'twin.macro';
import { useState } from 'react';
import { ThemeButton } from 'components';
import Link from 'next/link';
import { urlWithSlug } from 'helper';
import { useTrackedAction } from '@/helper/actionTracker';

const CardNotification = ({
  data,
  size,
  handleShowObligation,
}: {
  data: INotification;
  size?: 'sm' | 'md';
  handleShowObligation?: (
    notification_id: string,
    title: string,
    created_at: string,
    type: string
  ) => void;
}) => {
  const trackAction = useTrackedAction();
  const isRead = !!data.read_at;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleShowMore = (e: any) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    trackAction({
      action: 'show-more-notification',
      type: 'notification',
      category: 'user',
      status: 'success',
      data,
    });
  };

  const handleShowMoreObligation = (
    e: any,
    notification_id: string,
    title: string,
    created_at: string,
    type: string
  ) => {
    e.stopPropagation();
    handleShowObligation?.(notification_id, title, created_at, type);
    trackAction({
      action: 'show-more-obligation',
      type: 'notification',
      category: 'user',
      status: 'success',
      data,
    });
  };

  const isSmallSize = size === 'sm';

  const containerPadding = isSmallSize ? 'p-2' : 'px-3 py-4';

  const titleStyle: { text: TextDS2Ag; mb: 'mb-1' | 'mb-2' } = {
    text: isSmallSize ? 'Desktop/Caption/Bold' : 'Desktop/Body 2/Semi Bold',
    mb: isSmallSize ? 'mb-1' : 'mb-2',
  };

  const fileTypeClasses: TypeClasses = {
    pdf: 'text-danger-500',
    'application/pdf': 'text-danger-500',
    doc: 'text-info-500',
    'application/msword': 'text-info-500',
    ppt: 'text-warning-300',
    'image/jpeg': 'text-violet-700',
    'image/png': 'text-violet-700',
    csv: 'text-success-500',
    excel: 'text-success-500',
  };

  const getClassForFileType = (type: string) => {
    return type ? fileTypeClasses[type] : 'text-success-500';
  };

  const getStatStyle: TypeClasses = {
    new_obligations:
      'border-success-300 bg-success-300/10 text-success-300 italic text-xs',
    amanded_obligations:
      'border-warning-500 bg-warning-500/10 text-warning-500 text-xs',
    repealed_obligations:
      'border-danger-300 bg-danger-300/10 text-danger-300 text-xs',
    need_confirm_obligations:
      'border-[1px] border-warning-500 bg-[#DAA520] bg-opacity-10 text-warning-500 text-xs',
  };

  return (
    <>
      {[
        'compliance',
        'comment',
        'role',
        'role-compliance',
        'regulatory-framework',
        'obligation-updates',
        'settings',
        'change-priority-settings',
        'change-stage-settings',
        'company-management',
        'group',
        'obligation',
        'reminder-obligation',
        'document',
        'reminder-subscription',
        'regulation',
        'reminder-checklist',
      ].includes(data.type) ? (
        <>
          <div
            className={`${containerPadding} transition-colors duration-200 hover:cursor-pointer hover:bg-primary-100 rounded-[4px]`}
          >
            <div className="flex flex-row gap-3 items-start">
              <div className="p-1 border-neutral-75 border-[.5px] rounded-[6px] bg-neutral-50">
                {[
                  'compliance',
                  'comment',
                  'document',
                  'regulation',
                  'reminder-checklist',
                ].includes(data.type) && (
                  <Monitor
                    weight="duotone"
                    size={20}
                    className="text-primary-main"
                  />
                )}
                {[
                  'regulatory-framework',
                  'obligation-updates',
                  'obligation',
                  'reminder-obligation',
                ].includes(data.type) && (
                  <Stack
                    weight="duotone"
                    size={20}
                    className="text-primary-main"
                  />
                )}
                {[
                  'role',
                  'role-compliance',
                  'settings',
                  'change-priority-settings',
                  'change-stage-settings',
                  'group',
                ].includes(data.type) && (
                  <Gear
                    weight="duotone"
                    size={20}
                    className="text-primary-main"
                  />
                )}
                {['company-management', 'reminder-subscription'].includes(
                  data.type
                ) && (
                  <Image
                    src="/images/ic-buildings.svg"
                    width={20}
                    height={20}
                    alt="icon buildings"
                  />
                )}
              </div>
              <div className="w-[100%]">
                <div
                  className={`flex flex-row justify-between ${titleStyle.mb} items-center gap-4`}
                >
                  <div tw="flex flex-row gap-3 items-center">
                    <TextDS2
                      agDesktop="Desktop/Body 3/Bold"
                      agMobile="Desktop/Body 3/Bold"
                      color="Neutral/400"
                    >
                      {data.data?.title}
                    </TextDS2>
                    {!isSmallSize && (
                      <TextDS2
                        agDesktop="Desktop/Body 3/Semi Bold"
                        agMobile="Desktop/Body 3/Semi Bold"
                        color="Neutral/300"
                      >
                        {dayjs(data.created_at).tz(userTimezone).format('HH:mm A')}
                      </TextDS2>
                    )}
                  </div>
                  {!isRead && (
                    <div className="bg-danger-300 rounded-[50%] min-w-[16px] h-[16px]" />
                  )}
                </div>
                {[
                  'role',
                  'role-compliance',
                  'settings',
                  'company-management',
                  'group',
                  'reminder-subscription',
                ].includes(data.type) && (
                  <TextDS2
                    color="Neutral/400"
                    agDesktop="Desktop/Body 3/Medium"
                    agMobile="Desktop/Body 3/Medium"
                  >
                    {['role', 'role-compliance'].includes(data.type)
                      ? ['user-role-update'].includes(data.action)
                        ? data.data?.privilege_name
                        : data.data?.role
                      : ['group'].includes(data.type)
                        ? data.data?.group
                        : data.data?.body}
                  </TextDS2>
                )}
                {[
                  'compliance',
                  'regulatory-framework',
                  'obligation',
                  'reminder-obligation',
                  'document',
                  'comment',
                  'regulation',
                  'group',
                  'reminder-checklist',
                ].includes(data.type) && (
                  <>
                    {data.type === 'document' && data.action === 'share' ? (
                      <>
                        <div className="flex">
                          <Files
                            size={24}
                            weight="fill"
                            className={`${getClassForFileType(
                              data.data?.mime_type
                            )} mr-2`}
                          />
                          <TextDS2
                            color="Primary/300"
                            agDesktop="Desktop/Body 3/Semi Bold"
                            agMobile="Desktop/Body 3/Semi Bold"
                          >
                            {data.data?.file_name}
                          </TextDS2>
                        </div>
                      </>
                    ) : data.type === 'regulation' &&
                      data.action === 'share' ? (
                      <>
                        <div className="flex items-center mr-2">
                          <TextDS2
                            color="Neutral/300"
                            agDesktop="Desktop/Body 3/Bold"
                            agMobile="Desktop/Body 3/Bold"
                            className="mb-2"
                          >
                            {data.data?.regulation_title}
                          </TextDS2>
                        </div>
                        <p className="font-medium text-xs text-neutral-200">
                          {data.data?.regulation_subtitle}
                        </p>
                      </>
                    ) : data.type === 'obligation' &&
                      data.action === 'share' ? (
                      <div className="flex items-center mr-2">
                        <TextDS2
                          color="Neutral/300"
                          agDesktop="Desktop/Body 3/Bold"
                          agMobile="Desktop/Body 3/Bold"
                          className="mb-2"
                        >
                          {data.data?.obligation_title}
                        </TextDS2>
                        {data.data?.obligation_status && (
                          <NotificationStatus
                            status={data.data?.obligation_status}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        {data.action !== 'update-content' && (
                          <>
                            <div className="flex items-center gap-2 mb-2">
                              {data.data?.code && (
                                <TextDS2
                                  color="Neutral/300"
                                  agDesktop="Desktop/Body 3/Bold"
                                  agMobile="Desktop/Body 3/Bold"
                                >
                                  {data.data?.code}
                                </TextDS2>
                              )}
                              {data.data?.obligation_code && (
                                <TextDS2
                                  color="Neutral/300"
                                  agDesktop="Desktop/Body 3/Bold"
                                  agMobile="Desktop/Body 3/Bold"
                                >
                                  {data.data?.obligation_code}
                                </TextDS2>
                              )}
                              {data.data?.priority_code &&
                                data.data?.priority && (
                                  <NotificationPriority
                                    priority={data.data?.priority_code}
                                    text={data.data?.priority}
                                  />
                                )}
                              <NotificationStatus
                                status={data.data?.status_code}
                              />
                            </div>
                            {data.data?.obligation_name && (
                              <TextDS2
                                color="Neutral/400"
                                agDesktop="Desktop/Body 3/Semi Bold"
                                agMobile="Desktop/Body 3/Semi Bold"
                              >
                                {data.data?.obligation_name}
                              </TextDS2>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {(data?.data?.reminder_date ||
                      data?.data?.content ||
                      data?.data?.message) && (
                      <div className="flex rounded-[6px] bg-neutral-50 border-[1px] border-neutral-75 p-2 my-2 w-[100%] items-center">
                        {[
                          'claim',
                          'unclaimed',
                          'check-checklist',
                          'add-checklist',
                          'uncheck-checklist',
                          'assign-checklist',
                          'unassign-checklist',
                          'reminder',
                        ].includes(data.action) && (
                          <Checkbox
                            className="mr-1"
                            checked={
                              ['add-checklist', 'uncheck-checklist'].includes(
                                data.action
                              )
                                ? !!data?.data?.is_checked
                                : !!data?.data?.content?.checked_at
                            }
                            disabled
                          />
                        )}
                        <TextDS2
                          color="Neutral/400"
                          agDesktop="Desktop/Caption/Semi Bold"
                          agMobile="Desktop/Caption/Semi Bold"
                          className={`truncate ${isSmallSize ? 'w-[456px]' : ''}`}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${
                                ['reminder-checklist'].includes(data?.type) &&
                                ['reminder', 'due'].includes(data.action)
                                  ? `${data?.data?.content}${data?.data?.content_additional ? `, ${data?.data?.content_additional}` : ''}`
                                  : ['comment', 'compliance', 'group'].includes(
                                        data?.type
                                      ) && data.action !== 'share'
                                    ? [
                                        'add-checklist',
                                        'delete-checklist',
                                        'create',
                                      ].includes(data.action)
                                      ? data?.data?.content
                                      : data?.data?.content?.title
                                    : [
                                          'document',
                                          'obligation',
                                          'compliance',
                                          'regulation',
                                        ].includes(data?.type) &&
                                        data.action === 'share'
                                      ? data?.data?.message
                                      : data?.data?.reminder_date
                              }`,
                            }}
                          ></div>
                        </TextDS2>
                      </div>
                    )}
                  </>
                )}
                {['obligation'].includes(data.type) &&
                  data.action === 'update-content' && (
                    <div className="bg-neutral-50 border-neutral-75 p-4 rounded-[6px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-[24px]">
                          <div className="flex items-center flex-col gap-2">
                            <div
                              className={`flex items-center py-[2px] px-2 rounded-[6px] ${getStatStyle['new_obligations']}`}
                            >
                              New
                            </div>
                            <TextDS2
                              agDesktop="Desktop/H4/Semi Bold"
                              agMobile="Desktop/H4/Semi Bold"
                              color="Black"
                              className="!text-[20px] !leading-[20px]"
                            >
                              {data.data?.new_obligations}
                            </TextDS2>
                          </div>
                          <div className="flex gap-[24px]">
                            <div className="flex items-center flex-col gap-2">
                              <div
                                className={`flex items-center py-[2px] px-2 rounded-[6px] ${getStatStyle['amanded_obligations']}`}
                              >
                                Amended
                              </div>
                              <TextDS2
                                agDesktop="Desktop/H4/Semi Bold"
                                agMobile="Desktop/H4/Semi Bold"
                                color="Black"
                                className="!text-[20px] !leading-[20px]"
                              >
                                {data.data?.amanded_obligations}
                              </TextDS2>
                            </div>
                          </div>
                          <div className="flex gap-[24px]">
                            <div className="flex items-center flex-col gap-2">
                              <div
                                className={`flex items-center py-[2px] px-2 rounded-[6px] ${getStatStyle['repealed_obligations']}`}
                              >
                                Repealed
                              </div>
                              <TextDS2
                                agDesktop="Desktop/H4/Semi Bold"
                                agMobile="Desktop/H4/Semi Bold"
                                color="Black"
                                className="!text-[20px] !leading-[20px]"
                              >
                                {data.data?.repealed_obligations}
                              </TextDS2>
                            </div>
                          </div>
                          <div className="flex gap-[24px]">
                            <div className="flex items-center flex-col gap-2">
                              <div
                                className={`flex items-center py-[2px] px-2 rounded-[6px] ${getStatStyle['need_confirm_obligations']}`}
                              >
                                <Warning
                                  size={16}
                                  className="text-warning-300 mr-1"
                                  weight="fill"
                                />
                                Need Confirmation
                              </div>
                              <TextDS2
                                agDesktop="Desktop/H4/Semi Bold"
                                agMobile="Desktop/H4/Semi Bold"
                                color="Black"
                                className="!text-[20px] !leading-[20px]"
                              >
                                {data.data?.need_confirm_obligations}
                              </TextDS2>
                            </div>
                          </div>
                        </div>
                        {!isSmallSize && (
                          <div className="flex gap-2">
                            <Link
                              href={urlWithSlug(
                                '/obligation-updates?confirmation-status=need-confirmation'
                              )}
                              target="_blank"
                              passHref
                              rel="noopener noreferrer"
                            >
                              <ThemeButton className="rounded-[4px] !h-[40px] py-2 px-4 !text-xs">
                                View Need Confirmation Updates
                              </ThemeButton>
                            </Link>
                            <Link
                              href={urlWithSlug('/obligation-updates')}
                              target="_blank"
                              passHref
                              rel="noopener noreferrer"
                            >
                              <ThemeButton
                                variant="outlined"
                                className="rounded-[4px] !h-[40px] py-2 px-4 !text-xs bg-white"
                              >
                                View All Updates
                              </ThemeButton>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {data?.type === 'reminder-subscription' && (
                  <Link
                    href={data?.data?.admin_button}
                    target="_blank"
                    passHref
                    rel="noopener noreferrer"
                  >
                    <Button className="flex items-center gap-2 !text-[12px] !bg-white !border-[1px] !text-primary-main !border-primary-main mt-2 !h-[40px]">
                      Contact Admin <ArrowSquareOut size={18} />
                    </Button>
                  </Link>
                )}
                {['change-priority-settings'].includes(data.type) && (
                  <div className="flex gap-4 mb-2">
                    <div className="w-1/2">
                      <TextDS2
                        agDesktop="Desktop/Body 3/Semi Bold"
                        agMobile="Desktop/Body 3/Semi Bold"
                        color="Primary/Main"
                        className="mb-2"
                      >
                        After Change
                      </TextDS2>
                      <div className="bg-neutral-50 border-neutral-75 p-4 rounded-[6px]">
                        {data.data?.after.map(
                          ({
                            priority,
                            priority_code,
                          }: {
                            priority: string;
                            priority_code: TPriorities;
                          }) => (
                            <NotificationPriority
                              priority={priority_code}
                              text={priority}
                              key={priority_code}
                              type={data.type}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="w-1/2">
                      <TextDS2
                        agDesktop="Desktop/Body 3/Semi Bold"
                        agMobile="Desktop/Body 3/Semi Bold"
                        color="Primary/Main"
                        className="mb-2"
                      >
                        Before Change
                      </TextDS2>
                      <div className="bg-neutral-50 border-neutral-75 p-4 rounded-[6px]">
                        {data.data?.before.map(
                          ({
                            priority,
                            priority_code,
                          }: {
                            priority: string;
                            priority_code: TPriorities;
                          }) => (
                            <NotificationPriority
                              priority={priority_code}
                              text={priority}
                              key={priority_code}
                              type={data.type}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {['change-stage-settings'].includes(data.type) && (
                  <>
                    <div className="bg-neutral-50 border-neutral-75 p-4 rounded-[6px] mb-2">
                      <div className="flex justify-between">
                        <div
                          className={`flex ${isSmallSize ? 'gap-1' : 'gap-3'} items-center`}
                        >
                          {data.data?.after_stage.map(
                            (
                              {
                                title,
                              }: {
                                title: string;
                              },
                              i: number
                            ) => (
                              <>
                                <Image
                                  src={`/images/ic-${i === 3 ? 'check-circle' : 'circle-progress'}.svg`}
                                  alt="Stage"
                                  width={isSmallSize ? 20 : 32}
                                  height={isSmallSize ? 20 : 32}
                                  {...(i !== 2 && { className: 'opacity-50' })}
                                  key="title"
                                />
                                <p
                                  className={`${i !== 2 && 'opacity-50'} ${isSmallSize ? 'text-[10px]' : 'text-[14px]'} font-semibold`}
                                >
                                  {title}
                                </p>
                                {i !== 3 && (
                                  <div
                                    className={`bg-neutral-100 ${isSmallSize ? 'w-[12px]' : 'w-[64px]'} ${isSmallSize ? 'h-[3px]' : 'h-[6px]'}`}
                                  />
                                )}
                              </>
                            )
                          )}
                        </div>
                        <ThemeButton
                          className={
                            isSmallSize
                              ? 'ml-1 w-[84px] !px-1 rounded-[4px] !h-[32px] !text-[10px]'
                              : 'rounded-[4px] !h-[40px] py-2 px-4 !text-xs'
                          }
                        >
                          {data.data?.after_button_text}
                        </ThemeButton>
                      </div>
                      {isExpanded && (
                        <div className="flex justify-between mt-2">
                          <div
                            className={`flex ${isSmallSize ? 'gap-1' : 'gap-3'} items-center`}
                          >
                            {data.data?.before_stage.map(
                              (
                                {
                                  title,
                                }: {
                                  title: string;
                                },
                                i: number
                              ) => (
                                <>
                                  <Image
                                    src={`/images/ic-${i === 3 ? 'check-circle' : 'circle-progress'}.svg`}
                                    alt="Stage"
                                    width={isSmallSize ? 20 : 32}
                                    height={isSmallSize ? 20 : 32}
                                    {...(i !== 2 && {
                                      className: 'opacity-50',
                                    })}
                                    key="title"
                                  />
                                  <p
                                    className={`${i !== 2 && 'opacity-50'} ${isSmallSize ? 'text-[10px]' : 'text-[14px]'} font-semibold`}
                                  >
                                    {title}
                                  </p>
                                  {i !== 3 && (
                                    <div
                                      className={`bg-neutral-100 ${isSmallSize ? 'w-[12px]' : 'w-[64px]'} ${isSmallSize ? 'h-[3px]' : 'h-[6px]'}`}
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>
                          <ThemeButton
                            className={
                              isSmallSize
                                ? 'ml-1 w-[84px] !px-1 rounded-[4px] !h-[32px] !text-[10px]'
                                : 'rounded-[4px] !h-[40px] py-2 px-4 !text-xs'
                            }
                          >
                            {data.data?.before_button_text}
                          </ThemeButton>
                        </div>
                      )}
                    </div>
                    <div onClick={handleShowMore}>
                      <TextDS2
                        agDesktop="Desktop/Body 3/Medium"
                        agMobile="Desktop/Body 3/Medium"
                        color="Primary/300"
                        className="!text-xs"
                      >
                        Show {isExpanded ? 'Less' : 'More'}
                      </TextDS2>
                    </div>
                  </>
                )}
                {['company-management'].includes(data.type) &&
                  data.action !== 'none' && (
                    <div className="flex gap-4 mb-2">
                      <div className="w-1/2">
                        <TextDS2
                          agDesktop="Desktop/Body 3/Semi Bold"
                          agMobile="Desktop/Body 3/Semi Bold"
                          color="Primary/Main"
                          className="mb-2"
                        >
                          After Change
                        </TextDS2>
                        <div className="flex gap-2 bg-neutral-50 border-neutral-75 p-4 rounded-[6px]">
                          {data.action === 'edit-group' && (
                            <Users
                              weight="duotone"
                              size={20}
                              className="text-primary-main"
                            />
                          )}
                          {data.action === 'edit-privilege' && (
                            <UserList
                              weight="duotone"
                              size={20}
                              className="text-primary-main"
                            />
                          )}
                          <TextDS2
                            agDesktop="Desktop/Body 3/Semi Bold"
                            agMobile="Desktop/Body 3/Semi Bold"
                            color="Primary/Main"
                            className={`mb-2 ${isSmallSize ? '!text-xs' : ''}`}
                          >
                            {data.data?.after}
                          </TextDS2>
                        </div>
                      </div>
                      <div className="w-1/2">
                        <TextDS2
                          agDesktop="Desktop/Body 3/Semi Bold"
                          agMobile="Desktop/Body 3/Semi Bold"
                          color="Primary/Main"
                          className="mb-2"
                        >
                          Before Change
                        </TextDS2>
                        <div className="flex gap-2 bg-neutral-50 border-neutral-75 p-4 rounded-[6px]">
                          {data.action === 'edit-group' && (
                            <Users
                              weight="duotone"
                              size={20}
                              className="text-primary-main"
                            />
                          )}
                          {data.action === 'edit-privilege' && (
                            <UserList
                              weight="duotone"
                              size={20}
                              className="text-primary-main"
                            />
                          )}
                          <TextDS2
                            agDesktop="Desktop/Body 3/Semi Bold"
                            agMobile="Desktop/Body 3/Semi Bold"
                            color="Primary/Main"
                            className={`mb-2 ${isSmallSize ? '!text-xs' : ''}`}
                          >
                            {data.data?.after}
                          </TextDS2>
                        </div>
                      </div>
                    </div>
                  )}
                <div
                  className={`flex ${data.data?.footer ? 'justify-between' : 'justify-end'}`}
                >
                  {data.data?.footer && (
                    <div
                      onClick={(e) =>
                        handleShowMoreObligation(
                          e,
                          data.id,
                          data.data?.title,
                          data.created_at,
                          data.type
                        )
                      }
                    >
                      <TextDS2
                        agDesktop="Button/Small"
                        agMobile="Button/Small"
                        color="Primary/300"
                      >
                        {data.data?.footer}
                      </TextDS2>
                    </div>
                  )}
                  <TextDS2
                    agDesktop="Desktop/Caption/Semi Bold"
                    agMobile="Desktop/Caption/Semi Bold"
                    color="Neutral/200"
                  >
                    {dayjs(data.created_at).tz(userTimezone).format('ddd D MMM, HH:mm A')}
                  </TextDS2>
                </div>
              </div>
            </div>
          </div>
          <Divider className="mb-3" />
        </>
      ) : (
        <>
          <p>
            {data.type} & {data.action}
          </p>
          <Divider className="mb-3" />
        </>
      )}
    </>
  );
};

export default CardNotification;
