import { AxiosError } from 'axios';

export interface ErrorObj {
  name: string;
  message: string;
  header: string;
}

export function getErrorMessage(error: unknown) {
  const err = error as AxiosError<{ error: string | ErrorObj }>;
  const errorData = err.response?.data?.error;
  let message: string;

  if (typeof errorData === 'string') {
    message = errorData;
  } else {
    message = (errorData as ErrorObj)?.message;
  }

  return message;
}

export function ErrorState({ error }: { error: unknown }) {
  const message = getErrorMessage(error);

  return (
    <div className="text-sm text-center p-6 text-danger-300">{message}</div>
  );
}
