import { TextDS2, cx } from '@hol-jsp/dashboard-dsl';
import { XCircle, CaretCircleRight, CaretCircleLeft } from 'phosphor-react';
import classnames from 'classnames';
import Slider from 'react-slick';
import { useRef, useState } from 'react';
export interface ItemProp {
  value: string;
  label: string;
  name: string;
}

function PrevArrow({
  clickPrevArrow,
}: {
  clickPrevArrow?: () => void;
  className?: string;
}) {
  return (
    <div className={'my-auto mr-2 rounded-full'}>
      <CaretCircleLeft
        onClick={clickPrevArrow}
        className="!text-neutral-300 !border-neutral-100 my-auto cursor-pointer opacity-50 hover:opacity-100 rounded-full"
        height={32}
        width={32}
      />
    </div>
  );
}

function NextArrow({
  clickNextArrow,
}: {
  clickNextArrow?: () => void;
  className?: string;
}) {
  return (
    <div className={'my-auto shadow mr-2 rounded-full'}>
      <CaretCircleRight
        onClick={clickNextArrow}
        className="!text-neutral-300 !border-neutral-100 my-auto cursor-pointer opacity-50 hover:opacity-100 rounded-full"
        height={32}
        width={32}
      />
    </div>
  );
}

function ChipFilter({
  title = 'Filter By',
  items,
  sliderWrapperClass,
  itemClass,
  onValueChange,
  className,
}: {
  title?: string;
  items: ItemProp[];
  sliderWrapperClass?: string;
  itemClass?: string;
  onValueChange: (value: ItemProp[]) => void;
  className?: string;
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<any>(null);
  const goToNext = () => {
    sliderRef.current?.slickNext();
  };

  const goToPrevious = () => {
    sliderRef.current?.slickPrev();
  };
  const sliderSettings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    speed: 200,
    beforeChange: (_current: number, next: number) => setCurrentSlide(next),
    nextArrow:
      currentSlide <= items.length - 2 ? (
        <NextArrow clickNextArrow={goToNext} />
      ) : undefined,
    prevArrow:
      currentSlide >= 1 ? (
        <PrevArrow clickPrevArrow={goToPrevious} />
      ) : undefined,
  };

  const removeFilter = (item: ItemProp) => {
    const filteredFilter = items.filter((val) => val.value !== item.value);
    onValueChange(filteredFilter);
  };

  return (
    <div className={cx(className, 'flex w-full')}>
      <TextDS2
        agDesktop="Desktop/Caption/Medium"
        agMobile="Desktop/Caption/Medium"
        color="Neutral/300"
        className="my-auto w-max mr-2 whitespace-nowrap"
      >
        {title}
      </TextDS2>
      <div className={classnames('w-[90%]', sliderWrapperClass)}>
        <Slider ref={sliderRef} {...sliderSettings}>
          {items.map((item) => (
            <div
              key={item.label}
              className="!flex text-neutral-400 text-xs px-4 py-[7px] border font-semibold border-neutral-100 bg-neutral-50 !w-max rounded-3xl mr-2"
            >
              <div className={classnames('my-auto', itemClass)}>
                {item.label}
              </div>
              <XCircle
                onClick={() => {
                  removeFilter(item);
                }}
                className="ml-2 hover:transform hover:duration-200 hover:scale-110 cursor-pointer"
                height={16}
                width={16}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ChipFilter;
