import { Button, Checkbox, TextDS2, Tooltip } from '@hol-jsp/dashboard-dsl';
import classNames from 'classnames';
import { CheckableDataChecklistItem } from './types';
import { useRouter } from 'next/router';
import { encodeBase64, urlWithSlug } from 'helper';
import { t } from 'i18next';
import { ArrowRight } from 'phosphor-react';
import { ThemeButton } from '../ThemeButton';
import { ObligationFilterName } from 'page-components/compliance-monitor/filter';

export function DataChecklistItem<T extends CheckableDataChecklistItem>({
  item,
  className,
  searchQuery,
  onCheckboxChange,
  onClick,
  selected,
  content,
  hoverable,
  disabledAndChecked,
  disabled = false,
  tooltipText = "Can't select this item.",
  isShowViewComplianceBtn = false,
  itemDataTestId,
  itemCheckboxTestId,
}: {
  item: T;
  className?: string;
  searchQuery?: string;
  onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  selected?: boolean;
  content?: ({
    item,
    searchQuery,
  }: {
    item: T;
    searchQuery?: string;
  }) => React.ReactNode;
  hoverable?: boolean;
  disabledAndChecked?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  isShowViewComplianceBtn?: boolean;
  itemDataTestId?: string;
  itemCheckboxTestId?: string;
}) {
  const router = useRouter();

  const getQueryParams = (obligationId: string, title: string) => {
    const filter = [
      {
        name: ObligationFilterName,
        value: obligationId,
        label: title,
      },
    ];
    return `filter=${encodeBase64(JSON.stringify(filter))}`;
  };

  return (
    <div
      className={classNames(
        'transition-colors duration-200 hover:cursor-pointer px-1 pb-2',
        hoverable && `hover:bg-primary-100`,
        selected && 'bg-primary-100',
        className
      )}
      data-testid={itemDataTestId}
    >
      <div className="flex gap-1 items-start p-1" onClick={onClick}>
        <div className={`pt-[2.5px]`} onClick={(e) => e.stopPropagation()}>
          {disabled ? (
            <Tooltip content={tooltipText}>
              <div className="flex justify-center items-center min-w-[1rem] min-h-[1rem] rounded-[0.25rem] bg-neutral-75 border border-neutral-100 border-solid cursor-not-allowed"></div>
            </Tooltip>
          ) : (
            <>
              <Checkbox
                checked={item.checked || disabledAndChecked}
                value={item.id}
                onChange={onCheckboxChange}
                disabled={disabledAndChecked || disabled}
                tw={disabled ? '!cursor-not-allowed' : ''}
                className={disabled ? '!cursor-not-allowed' : ''}
                data-testid={itemCheckboxTestId}
              />
            </>
          )}
        </div>
        <div className="w-full">{content?.({ item, searchQuery }) ?? null}</div>
      </div>
      {isShowViewComplianceBtn && item?.confirmation_status && (
        <div
          className="flex justify-between bg-info-100 rounded-full px-6 py-3"
          onClick={() =>
            window.open(
              urlWithSlug(`/compliance-monitor?q=${item.code}`),
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <TextDS2
            agDesktop="Desktop/Caption/Semi Bold"
            agMobile="Desktop/Caption/Semi Bold"
            color="Primary/300"
          >
            {`${item?.count_compliance || 0} ${t(
              'obligationUpdates.obligationInComplianceMonitor'
            )}`}
          </TextDS2>
          <ThemeButton size="sm" variant="text" className="!p-0 flex !h-max">
            <div className="my-auto mr-1">
              {t('obligationUpdates.viewComplianceMonitor')}
            </div>
            <ArrowRight size={18} />
          </ThemeButton>
        </div>
      )}
    </div>
  );
}
