import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Text, cx } from '@hol-jsp/dashboard-dsl';
import { Bell, Gear, Question } from 'phosphor-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  Can,
  deleteCookies,
  getOrgSettingsFromCookies,
  getUser,
  usePermission,
} from 'helper';
import { useLogout } from 'modules/unauthenticated/hooks';
import NotificationBubble from './NotificationBubble';
import SettingsBubble from './SettingsBubble';
import AccountBubble from './AccountBubble';
import Search from './Search';
import HelpBubble from './HelpBubble';
import ModalSendFeedback from './ModalSendFeedback';

import {
  useGetNotificationById,
  useGetNotifications,
} from 'modules/notification/hooks';
import { INofitificationParam } from 'types/notification';
import ModalShowMore from './ModalShowMore';
import { Meta } from 'types/general';
import { useTrackedAction } from '@/helper/actionTracker';

function debounce(fn: any, time: number) {
  let timeoutId: any;
  return wrapper;
  function wrapper(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}

function Menu({
  active,
  children,
  href,
  style,
  ...props
}: {
  active: boolean;
  children: React.ReactNode;
  href: string;
  style: Record<string, any>;
}) {
  const trackAction = useTrackedAction();
  // Many lines have been commented out because they are not included in this sprint.
  const orgSettings = getOrgSettingsFromCookies();
  const forcedTextColor = useMemo(() => {
    if (!active && orgSettings?.header_text_and_icon_color) {
      return orgSettings.header_text_and_icon_color;
    } else if (active && orgSettings?.header_active_text_and_icon_color) {
      return orgSettings.header_active_text_and_icon_color;
    }
    return '';
  }, [active, orgSettings]);

  return (
    <Link
      href={href}
      className="flex items-center py-[4px] px-[24px] whitespace-nowrap rounded-md cursor-default transition-all ease-linear"
      style={style}
      {...props}
    >
      <div
        className={cx('font-bold text-sm leading-6 py-2 hover:cursor-pointer', {
          'border-b-primary-400 border-b-2': active,
          'text-primary-400': active,
          'text-neutral-400': !active,
        })}
        style={{
          color: forcedTextColor,
          borderBottomColor: active ? forcedTextColor : '',
        }}
        onClick={() =>
          trackAction({
            action: 'click-header-navigation',
            type: 'header-navigation',
            category: 'Header Navigation',
            status: 'success',
            data: {
              href,
              label: children,
            },
          })
        }
      >
        <Text>{children}</Text>
      </div>
    </Link>
  );
}

export function Header() {
  //   {
  //   isUpdating,
  //   updateProgress,
  // }: {
  //   isUpdating?: boolean;
  //   updateProgress?: string;
  // }
  const apiUrl =
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? '/api-client'
      : process.env.NEXT_PUBLIC_CLIENT_API;
  const router = useRouter();
  const { t } = useTranslation() as any;
  const { hasPermissions } = usePermission();
  const orgSettings = getOrgSettingsFromCookies();
  const defaultNotificationParameters = {
    page: 1,
    limit: 10,
    language_id: 'id',
    type: null,
    read_at: null,
  };
  const [showNotification, setShowNotification] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const user = getUser();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isShowObligation, setIsShowObligation] = useState<boolean>(false);

  const [notificationParameters, setNotificationParameters] =
    useState<INofitificationParam>(defaultNotificationParameters);
  const [notificationDetailParameters, setNotificationDetailParameters] =
    useState<Meta>({
      page: 1,
      limit: 10,
      total_pages: 1,
      total_records: 0,
    });
  const [notificationDetailData, setNotificationDetailData] = useState<any>();

  const [complianceId, setComplianceId] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [dataCreatedAt, setDataCreatedAt] = useState<string>('');
  const [modalType, setModalType] = useState<string>('');

  const {
    data,
    isLoading: isLoadingNotifDetails,
    refetch: refetchNotificationDetail,
  } = useGetNotificationById(complianceId, notificationDetailParameters);

  const trackAction = useTrackedAction();

  const handleShowMoreNotifDetails = () => {
    if (
      notificationDetailParameters.limit <
      notificationDetailParameters.total_records
    )
      setNotificationDetailParameters((prevState) => ({
        ...prevState,
        limit: prevState.limit + 10,
      }));
  };

  useEffect(() => {
    if (data) {
      setNotificationDetailParameters(data.data.meta);
    }
  }, [data, notificationDetailData]);

  useEffect(() => {
    if (complianceId) {
      refetchNotificationDetail();
    }
  }, [complianceId, refetchNotificationDetail]);

  const handleShowObligation = (
    compliance_id: string,
    title: string,
    created_at: string,
    type: string
  ) => {
    setIsShowObligation(true);
    setComplianceId(compliance_id);
    setModalTitle(title);
    setDataCreatedAt(created_at);
    setModalType(type);
  };

  const {
    data: notificationResponse,
    isLoading: isLoadingNotification,
    refetch,
  } = useGetNotifications(notificationParameters);

  const { meta: metaNotification, data: dataNotification } =
    notificationResponse?.data || {};
  const paginationNotification = {
    page: notificationParameters.page,
    limit: notificationParameters.limit,
    total_data: metaNotification?.total_records,
    page_count: metaNotification?.total_pages,
  };

  const getNextNotification = () => {
    if (
      paginationNotification.total_data &&
      paginationNotification.limit < paginationNotification.total_data
    ) {
      const limit = paginationNotification?.limit + 10;

      setNotificationParameters({ ...notificationParameters, limit });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetch]);

  const onSuccessLogout = () => {
    trackAction({
      action: 'click-topbar-logout',
      type: 'topbar-logout',
      category: 'Topbar',
      status: 'success',
      data: {},
    });
    deleteCookies('token');
    deleteCookies('companies');
    deleteCookies('slug');
    deleteCookies('user');
    deleteCookies('recent_search');
    setTimeout(() => {
      router.push('/login');
    }, 200);
  };

  const { mutate } = useLogout({
    onSuccess() {
      onSuccessLogout();
      // router.reload();
    },
  });

  const logout = () => {
    // mutate();
    onSuccessLogout();
  };

  const pathname = router.asPath;
  const companySlug = pathname.split('/')[1];
  const menus = [
    {
      label: t('home'),
      href: `/${companySlug}/home`,
      'data-testid': 'navbar-home',
      active: pathname.includes('/home'),
      permissions: [
        'view-compliance-dashboard',
        'view-all-user-group',
        'download-report',
        'view-regframe-dashboard',
      ],
    },
    {
      label: t('compliance.compliance'),
      href: `/${companySlug}/compliance-monitor`,
      'data-testid': 'navbar-compliance',
      active:
        pathname.includes('/compliance-monitor') ||
        pathname.includes('/compliance-attachment'),
      permissions: [
        'view-assigned-compliance',
        'view-all-compliance',
        'deactivate-compliance',
        'duplicate-compliance',
      ],
    },
    {
      label: t('regulatoryFramework'),
      href: `/${companySlug}/sector`,
      'data-testid': 'navbar-regframe',
      active:
        pathname.includes('/sector') ||
        pathname.includes('/regulation') ||
        pathname.includes('/obligation-updates'),
      permissions: [
        'view-obligation',
        'switch-obligation-status',
        'download-regulation-doc',
        'download-obligation-update',
      ],
    },
    {
      label: t('activity'),
      href: `/${companySlug}/activity`,
      'data-testid': 'navbar-activity',
      active: pathname.includes('/activity'),
      permissions: ['view-all-activity', 'view-user-group-activity'],
    },
  ];

  const forcedTextColor = useMemo(() => {
    if (orgSettings?.header_text_and_icon_color) {
      return orgSettings.header_text_and_icon_color;
    }
    return '';
  }, [orgSettings]);

  const forcedActiveTextColor = useMemo(() => {
    if (orgSettings?.header_active_text_and_icon_color) {
      return orgSettings.header_active_text_and_icon_color;
    }
    return '';
  }, [orgSettings]);

  const handleClickLogo = () => {
    trackAction({
      action: 'click-topbar-logo',
      type: 'topbar-logo',
      category: 'Topbar',
      status: 'success',
    });
    router.push(`/${companySlug}/home`);
  };

  // const textOverlayStyle = {
  //   background: `linear-gradient(to right, white ${updateProgress}%, #607A93 ${100 - Number(updateProgress)}%)`,
  //   WebkitBackgroundClip: 'text',
  //   color: 'transparent',
  // };

  return (
    <>
      <div className="fixed w-full top-0 z-40" data-testid="topbar">
        {/* {isUpdating && (
          <div className="w-full neutral/white h-[16px] p-[2px] relative">
            <div
              className="h-[12px] bg-primary-400"
              style={{ width: `${updateProgress}%` }}
            />
            <div
              style={textOverlayStyle}
              className="w-full absolute top-0 left-0 h-full flex items-center justify-center text-[8px]"
            >
              <span>Initialize Content Data... {updateProgress}%</span>
            </div>
          </div>
        )} */}
        <header
          className={cx(
            'relative bg-blue-100 pl-[26px] pr-[40px] py-[8px] font-sans z-50 h-[68px] max-h-[68px] flex items-center'
          )}
          style={
            orgSettings?.header_background_color
              ? { backgroundColor: orgSettings?.header_background_color }
              : {}
          }
        >
          <div className="w-full">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Link
                  href="/"
                  className="mr-[16px] w-[184px] flex items-center gap-2"
                  title="logo-hol"
                  id="logo-rcs-topbar"
                >
                  <img
                    alt="Logo RCS"
                    title="Logo RCS"
                    src="/images/logo-rcs.svg"
                    data-testid="topbar-logo"
                    className="max-w-[84px] max-h-[25.6px]"
                    onClick={handleClickLogo}
                  />
                  {orgSettings?.logo_url && (
                    <>
                      <div className="h-[16px] w-0 border-[1px] border-primary-400" />
                      <img
                        alt="Logo RCS"
                        title="Logo RCS"
                        src={`${apiUrl}${orgSettings?.logo_url}`}
                        className="max-w-[84px] max-h-[25.6px]"
                        data-testid="topbar-logo"
                        width="100%"
                      />
                    </>
                  )}
                </Link>

                {!isSearchFocused &&
                  menus.map(
                    (menu) =>
                      // TODO: implement all permissions
                      ((menu.permissions?.length > 0 &&
                        hasPermissions(menu.permissions)) ||
                        menu.permissions?.length === 0) && (
                        <Menu
                          key={menu.href}
                          active={menu.active}
                          href={menu.href}
                          data-testid={menu['data-testid']}
                          style={{}}
                        >
                          {menu.label}
                        </Menu>
                      )
                  )}
              </div>
              <div className="flex items-center text-neutral-400 gap-4">
                <Search
                  className={
                    isSearchFocused
                      ? 'max-w-[888px] w-[66vw] search-header-nav focused'
                      : 'w-[296px] search-header-nav not-focused'
                  }
                  placeholder={t('search')}
                  setIsFocus={setIsSearchFocused}
                  isFocus={isSearchFocused}
                  onClickSearch={() => {
                    trackAction({
                      action: 'click-topbar-search',
                      type: 'topbar-search',
                      category: 'Topbar',
                      status: 'success',
                      data: {},
                    });
                  }}
                />
                <Can permissions={['view-notification']}>
                  <button
                    className="text-primary-500"
                    data-testid="button-notification"
                    onClick={() => {
                      trackAction({
                        action: 'click-topbar-notification',
                        type: 'topbar-notification',
                        category: 'Topbar',
                        status: 'success',
                        data: {},
                      });
                      setShowNotification(!showNotification);
                      setIsShowObligation(false);
                      refetch();
                    }}
                    style={{
                      color:
                        showNotification ||
                        router.asPath.includes('/notification')
                          ? forcedActiveTextColor
                          : forcedTextColor,
                      position: 'relative',
                    }}
                  >
                    <Bell size={24} />
                    {Number(metaNotification?.total_unread) > 0 && (
                      <div className="flex w-[16px] h-[16px] bg-[#EC6660] rounded-full absolute top-[-4px] right-[-4px] text-white items-center justify-center text-[8px]">
                        {metaNotification?.total_unread}
                      </div>
                    )}
                  </button>
                </Can>
                <button
                  className="text-primary-500"
                  data-testid="button-help"
                  onClick={() => {
                    setShowHelp(!showHelp);
                  }}
                  style={{
                    color: showHelp ? forcedActiveTextColor : forcedTextColor,
                  }}
                >
                  <Question size={24} />
                </button>
                <button
                  onClick={() => {
                    setShowSettings(!showSettings);
                    trackAction({
                      action: 'click-topbar-settings',
                      type: 'topbar-settings',
                      category: 'Topbar',
                      status: 'success',
                      data: {},
                    });
                  }}
                  data-testid="button-settings"
                  style={{
                    color:
                      showSettings ||
                      router.asPath.includes('/personal-setting') ||
                      router.asPath.includes('/rcs-setting') ||
                      router.asPath.includes('/company-management')
                        ? forcedActiveTextColor
                        : forcedTextColor,
                  }}
                >
                  <Gear size={24} />
                </button>
                <Avatar
                  size="sm"
                  data-testid="image-profile"
                  name={user?.name}
                  onClick={() => {
                    setShowProfileMenu(!showProfileMenu);
                    trackAction({
                      action: 'click-topbar-profile',
                      type: 'topbar-profile',
                      category: 'Topbar',
                      status: 'success',
                      data: {},
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </header>

        {showNotification && (
          <NotificationBubble
            isLoading={isLoadingNotification}
            data={dataNotification}
            pagination={paginationNotification}
            meta={metaNotification}
            getNext={getNextNotification}
            onClick={() => setShowNotification((prev) => !prev)}
            refetchNotificationList={refetch}
            handleShowObligation={handleShowObligation}
          />
        )}

        <ModalShowMore
          isShowObligation={isShowObligation}
          setIsShowObligation={setIsShowObligation}
          // data={notificationDetailData}
          data={data?.data.data}
          title={modalTitle}
          createdAt={dataCreatedAt}
          handleShowMore={handleShowMoreNotifDetails}
          params={notificationDetailParameters}
          setParams={setNotificationDetailParameters}
          setNotificationDetailData={setNotificationDetailData}
          isFetching={isLoadingNotifDetails}
          modalType={modalType}
        />

        {showSettings && (
          <SettingsBubble onClick={() => setShowSettings((prev) => !prev)} />
        )}

        {showProfileMenu && (
          <AccountBubble
            onClick={() => setShowProfileMenu((prev) => !prev)}
            logout={logout}
          />
        )}
        {showHelp && (
          <HelpBubble
            onClick={() => setShowHelp((prev) => !prev)}
            showFeedbackModal={showFeedbackModal}
            setShowFeedbackModal={setShowFeedbackModal}
          />
        )}
      </div>
      {showFeedbackModal && (
        <ModalSendFeedback
          isOpen={showFeedbackModal}
          setIsOpen={setShowFeedbackModal}
        />
      )}
    </>
  );
}

export default Header;
