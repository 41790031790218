import { useMutation, useQuery } from 'react-query';
import {
  getOrgSettings,
  sendFeedback,
  shareItem,
  trackActivityLog,
  updateSettings,
  uploadSettingsImage,
} from './service';
import {
  IActivityPayload,
  RCSSettings,
  SendFeedbackParams,
  ShareItemPayload,
} from 'types/general';

export const useGetOrgSettings = () =>
  useQuery({
    queryKey: 'org-settings',
    queryFn: getOrgSettings,
  });

export const useUpdateSettings = () =>
  useMutation({
    mutationFn: (params: RCSSettings) => updateSettings(params),
  });

export const useShareItem = () =>
  useMutation({
    mutationFn: (params: ShareItemPayload) => shareItem(params),
  });

export const useUploadSettingsImage = () =>
  useMutation({
    mutationFn: (file: File) => uploadSettingsImage(file),
  });

export const useSendFeedback = () =>
  useMutation({
    mutationFn: (params: SendFeedbackParams) => sendFeedback(params),
  });

export const useActivityLog = () =>
  useMutation({
    mutationFn: (payload: IActivityPayload) => trackActivityLog(payload),
  });
