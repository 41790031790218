import { TextDS2 } from '@hol-jsp/dashboard-dsl';
import { ObligationRegulation } from './types';
import * as Accordion from '@radix-ui/react-accordion';
import { CaretDown, CaretRight } from 'phosphor-react';
import { Divider } from '@hol-jsp/dashboard-dsl';
import { t } from 'i18next';
import Link from 'next/link';
import 'twin.macro';
import * as Tabs from '@radix-ui/react-tabs';
import { EmptyState } from '../Common';
import { ThemeButton } from '../ThemeButton';
import { useTrackedAction } from '@/helper/actionTracker';
import { urlWithSlug } from '@/helper/index';

export function ObligationRegulationAccordion({
  items,
  regulationId,
}: {
  items: ObligationRegulation[];
  regulationId: string;
}) {
  return (
    <Accordion.Root type="multiple" className="space-y-4">
      {items?.map((item) => (
        <ObligationRegulationAccordionItem
          item={item}
          key={item.id}
          regulationId={regulationId}
        />
      ))}
    </Accordion.Root>
  );
}

export function ObligationRegulationAccordionItem({
  item,
  regulationId,
}: {
  item: ObligationRegulation;
  regulationId: string;
}) {
  const trackAction = useTrackedAction();

  const onViewRegulation = () => {
    trackAction({
      action: 'view-regulation',
      type: 'compliance-monitor-details',
      category: 'Compliance Monitor Pages',
      status: 'success',
      data: {
        ...item,
        regulationId,
      },
    });
  };

  return (
    <Accordion.Item value={item.id} className="bg-neutral-50 p-4">
      <Accordion.Header className="flex justify-between items-center">
        <Accordion.Trigger className="AccordionTrigger flex items-center gap-2 text-left">
          <CaretDown
            size={24}
            weight="bold"
            className="AccordionChevron text-neutral-500 shrink-0"
          />
          <TextDS2
            agDesktop="Desktop/Body 3/Semi Bold"
            agMobile="Desktop/Body 3/Semi Bold"
            className="!text-primary-300"
          >
            {item.title}
          </TextDS2>
        </Accordion.Trigger>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={urlWithSlug(`/regulation/${regulationId}/${item.id}`)}
        >
          <ThemeButton
            variant="text"
            size="sm"
            className="!whitespace-nowrap inline-flex items-center gap-2"
            onClick={onViewRegulation}
          >
            {t('obligation.viewRegulation')}
            <CaretRight size={18} weight="bold" />
          </ThemeButton>
        </Link>
      </Accordion.Header>

      <Accordion.Content className="CollapsibleContent text-sm text-neutral-500">
        <Tabs.Root defaultValue="obligations">
          <Tabs.List className="flex items-center gap-2 mt-4 mb-4">
            <Tabs.Trigger
              value="obligations"
              className="border rounded-full font-nunito-sans font-bold px-4 py-1 border-neutral-100 text-neutral-400 data-[state=active]:bg-primary-100 data-[state=active]:border-primary-100 data-[state=active]:text-primary-400"
            >
              Article of Obligation
            </Tabs.Trigger>
            <Tabs.Trigger
              value="penalties"
              className="border rounded-full font-nunito-sans font-bold px-4 py-1 border-neutral-100 text-neutral-400 data-[state=active]:bg-danger-100 data-[state=active]:border-danger-100 data-[state=active]:text-danger-500"
            >
              Article of Penalty
            </Tabs.Trigger>
          </Tabs.List>

          <Divider className="mt-2 mb-4" />

          <Tabs.Content value="obligations" className="space-y-4">
            {item.obligations ? (
              item.obligations.map((obligationItem, index) => (
                <div key={index}>
                  <h4 className="font-semibold mb-1 capitalize">
                    {obligationItem.label}
                    &nbsp;
                    {obligationItem.name}
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: obligationItem.description,
                    }}
                  ></div>
                </div>
              ))
            ) : (
              <EmptyState hideImage message="No Data" />
            )}
          </Tabs.Content>
          <Tabs.Content value="penalties" className="space-y-4">
            {item.penalties ? (
              item.penalties.map((penaltyItem, index) => (
                <div key={index}>
                  <h4 className="font-semibold mb-1 capitalize">
                    {penaltyItem.label}
                    &nbsp;
                    {penaltyItem.name}
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: penaltyItem.description,
                    }}
                  ></div>
                </div>
              ))
            ) : (
              <EmptyState hideImage message="No Data" />
            )}
          </Tabs.Content>
        </Tabs.Root>
      </Accordion.Content>
    </Accordion.Item>
  );
}
