import { useCallback } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';
import { useMutation } from 'react-query';
import { IActivityPayload } from '@/types/general';
import { trackActivityLog } from '@/modules/general/service';

const parser = new UAParser();
let fpPromise: Promise<any> | null = null;

const getFingerprint = async () => {
  if (!fpPromise) {
    fpPromise = FingerprintJS.load();
  }
  return fpPromise;
};

const actionTracker = async ({
  type,
  category,
  action,
  status,
  data,
  mutate,
}: {
  type: string;
  category: string;
  action: string;
  status: string;
  data?: Record<string, any>;
  mutate: (payload: any) => void;
}) => {
  try {
    const fp = await getFingerprint();
    const result = await fp.get();
    const deviceType = parser.getUA();
    const deviceId = result.visitorId;

    if (deviceId) {
      mutate({
        action,
        type,
        category,
        url: window.location.href,
        status,
        data,
        deviceId,
        deviceType,
      });
    }
  } catch (error) {
    console.error('Error tracking action:', error);
  }
};

// Hook that combines useActivityLog and actionTracker
const useTrackedAction = () => {
  const { mutate } = useMutation({
    mutationFn: (payload: IActivityPayload) => trackActivityLog(payload),
  });

  return useCallback(
    (params: {
      type: string;
      category: string;
      action: string;
      status: string;
      data?: Record<string, any>;
    }) => {
      actionTracker({
        ...params,
        mutate,
      });
    },
    [mutate]
  );
};

export { useTrackedAction, actionTracker };
