import axios from 'modules/axios';
import {
  INofitificationParam,
  INotificationResponse,
} from 'types/notification';
import { getCompanyId, getUser } from 'helper/utils';
import { Meta, RootResponse } from 'types/general';

export const getNotifications = async (params: INofitificationParam) =>
  axios
    .get<INotificationResponse>('/v1/notifications', {
      params: { ...params, company_id: getCompanyId() },
    })
    .then((res) => res);

export const getNotificationById = async (id: string, params: Meta) =>
  axios
    .get<INotificationResponse>(`/v1/notifications/${id}`, {
      params: {
        company_id: getCompanyId(),
        language_id: 'id',
        ...params,
      },
    })
    .then((res) => res);

export const postReadAllNotification = async () =>
  axios
    .post<
      RootResponse<any>
    >(`/v1/notifications/read-all?company_id=${getCompanyId()}&user_id=${getUser().id}`)
    .then((res) => res);

export const postReadNotificationById = async (id: string) =>
  axios
    .post<
      RootResponse<any>
    >(`/v1/notifications/read/${id}?company_id=${getCompanyId()}&user_id=${getUser().id}`)
    .then((res) => res);
